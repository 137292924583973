/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/formstone@1.4.22/dist/js/core.min.js
 * - /npm/formstone@1.4.22/dist/js/swap.min.js
 * - /npm/formstone@1.4.22/dist/js/tabs.min.js
 * - /npm/formstone@1.4.22/dist/js/mediaquery.min.js
 * - /npm/formstone@1.4.22/dist/js/dropdown.min.js
 * - /npm/svgxuse@1.2.6/svgxuse.min.js
 * - /npm/jquery-validation@1.20.0/dist/jquery.validate.min.js
 * - /npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.js
 * - /npm/slick-carousel@1.8.1/slick/slick.min.js
 * - /npm/vanilla-cookieconsent@3.0.0/dist/cookieconsent.umd.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
